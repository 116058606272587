@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.width {
  width: 27rem;
}

.height {
  min-height: 60vh;
  height: 80vh;
}

@media only screen and (max-width: 1440px) {
  .width {
    width: 24rem;
  }
}

@media only screen and (max-width: 1023px) {
  .width {
    width: 23rem;
  }
}

@media only screen and (max-width: 640px) {
  .width {
    width: 100vw;
    max-width: 100vw;
  }

  .height {
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
  }
}

.paging-dot {
  width: 20px;
  height: 20px;
  padding-right: 2px;
}
.product-carousal .paging-dot,
.seller-carousal .paging-dot {
  display: none;
}

.paging-dot {
  width: 20px;
  height: 20px;
  padding-right: 2px;
}
.product-carousal .paging-dot,
.seller-carousal .paging-dot {
  display: none;
}
.show-cat .paging-dot {
  display: none;
}
.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 5vh;
  text-align: center;
  padding: 12px;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  background-color: lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}

.noscrollbar::-webkit-scrollbar {
  height: 4px;
  border-radius: 10px;
}

.noscrollbar::-webkit-scrollbar-thumb {
  background: rgb(77, 67, 67);
  border-radius: 10px;
}

.noscrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  margin: 30px auto;
  cursor: pointer;
}

.page,
.next,
.previous,
.break {
  list-style: none;
  font-weight: 600;
}

.active {
  background-clip: border-box;
  background: -webkit-linear-gradient(#4a1d85, #156fbc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
