@tailwind base;
@tailwind components;
@tailwind utilities;

.title {
  font-size: 17px;
  font-weight: 700;
  color: #333333;
}
.description {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
}
.textcolor {
  color: #333333;
}
.backgroundcolor {
  background-color: #333333;
}

.swiper-pagination-bullet {
  border: 1px solid white !important;
  background-color: black !important;
}

.swiper-pagination-bullet-active {
  background-color: blue !important;
}

.leftcover {
  animation: leftBookFrame 1s ease-in-out;
}

.rightcover {
  animation: rightBookFrame 1s ease-in-out;
}

@keyframes leftBookFrame {
  0% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes rightBookFrame {
  0% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
.paging-dot {
  width: 20px;
  height: 20px;
  padding-right: 2px;
}
.product-carousal .paging-dot,
.seller-carousal .paging-dot {
  display: none;
}
.show-cat .paging-dot {
  display: none;
}

.noscrollbar::-webkit-scrollbar {
  display: none;
}
.seller-carousal {
  background-color: white;
  border-radius: 0px 0px 10px 10px;
  padding-bottom: 18px;
}

.productDetails .swiper-button-prev {
  color: black;
}

.productDetails .swiper-button-next {
  color: black;
}

.cartProduct .parentHover {
  display: none;
}

.cartProduct .parent:hover .parentHover {
  display: block;
}
